import React from 'react'
import { required, Create, SimpleForm, TextInput } from 'react-admin'

export const CategoryCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()}/>
      <TextInput source="description"/>
    </SimpleForm>
  </Create>
)
