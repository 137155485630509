import React from 'react'
import { Show, TabbedShowLayout, TextField, Tab, DateField, ArrayField, Datagrid, BooleanField, ReferenceField } from 'react-admin'
import { getOpportunityTitle } from '../../helpers/opportunityHelper'

export const CustomTextField  = ({source, record={}}) => {
  const values = {...record}
  values[source] = getOpportunityTitle(record[source])
  return <TextField source={source} record={values} />
}

export const JobSoughtShow = props => (
  <Show {...props} >
    <TabbedShowLayout>
      <Tab label="Utilisateur">
        <ReferenceField source="userId" reference="users"><TextField source="firstName" /></ReferenceField>
        <TextField source="contact.phoneNumber" />
        <DateField source="createdOn" />
      </Tab>
      <Tab label="Contact">
        <TextField source="contact.phoneNumber" />
        <TextField source="contact.availabilityDay" />
        <TextField source="contact.availabilityHour" />
        <TextField source="contact.availabilityDetail" />
        <TextField source="contact.unwantedLocation" />
      </Tab>
      <Tab label="Localisation">
        <TextField source="location.region" />
        <TextField source="location.department" />
        <TextField source="location.locality" />
      </Tab>
      <Tab label="Postes">
        <ArrayField label="" source="jobChoices">
          <Datagrid>
            <CustomTextField label="Catégorie" source="category" />
            <BooleanField label="CDD" source="cddContract" />
            <BooleanField label="CDI" source="cdiContract" />
          </Datagrid>
        </ArrayField>
      </Tab>

    </TabbedShowLayout>
  </Show>
)
