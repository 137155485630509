import React from 'react'
import { List, Datagrid, TextField, Filter, TextInput, BooleanField, BooleanInput} from 'react-admin'

const CompanyFilter = props => (
  <Filter {...props}>
    <TextInput label='Filtrer par nom' source='name' alwaysOn/>
    <TextInput label='Filtrer par description' source='description' alwaysOn/>
    <BooleanInput label='Enseignes actives' source='active' alwaysOn/>
  </Filter>
)

export const CompanyList = props => (
  <List filters={<CompanyFilter/>} filterDefaultValues={{ active: false }} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <BooleanField source="active" />
    </Datagrid>
  </List>
);
