import React from 'react'
import { required, Create, SimpleForm, TextInput } from 'react-admin'
import MarkdownInput from 'ra-input-markdown'

export const CompanyCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()}/>
      <TextInput source="recruitmentEmail" type="email" validate={required()}/>
      <MarkdownInput label="Description" source="description" fullWidth multiline/>
    </SimpleForm>
  </Create>
);
