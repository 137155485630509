const OPPORTUNITIES_TYPE = {
  assemblerSeller: 'Monteur(se)-vendeur(se)',
  substituteOptician: 'Opticien(ne) remplaçant(e)',
  collaboratingOptician: 'Opticien(ne) collaborateur',
  opticianOptometrist: 'Opticien(ne) Optométriste',
  technicalManager: 'Responsable technique',
  assistantManager: 'Responsable adjoint(e)',
  storeManager: 'Responsable de magasin',
  areaManager: 'Responsable de secteur',
  networkFacilitator: 'Animateur réseau',
};

export const getOpportunityTitle = (category) => {
  return OPPORTUNITIES_TYPE[category]
}
