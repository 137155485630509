import React from 'react'
import { useState, useEffect } from 'react';
import { useDataProvider, Loading, Edit} from 'react-admin'
import { PostEditForm } from './PostEditForm'

export const PostEdit = props => {
  const postId = props.id;
  const dataProvider = useDataProvider();
  const [comments, setComments] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider.getOne(`comments/post`, {id: postId})
      .then((response) => {
        const data = response.data?.comments;
        setComments(data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      })
  }, [])

  if (loading){
    return <Loading />;
  }

  return (
    <Edit undoable={false} {...props}>
      <PostEditForm comments={comments}/>
    </Edit>
  )
}
