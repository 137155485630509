import React from 'react'
import { required, Create, SimpleForm, ReferenceInput, SelectInput, TextInput } from 'react-admin'
import MarkdownInput from 'ra-input-markdown';

export const PostCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="categoryId" reference="categories">
        <SelectInput optionText="name" validate={required()}/>
      </ReferenceInput>
      <TextInput source="title" validate={required()} multiline fullWidth/>
      <TextInput source="description" multiline fullWidth/>
      <MarkdownInput source="content" validate={required()} multiline fullWidth/>
    </SimpleForm>
  </Create>
)
