export const resourcesFrMessages = {
  resources: {
    posts: {
      name: 'Publication |||| Publications',
      fields: {
        id: 'Identifiant',
        name: 'Nom',
        content: 'Contenu',
        categoryId: 'Catégorie',
        createdOn: 'Créé le',
        authorId: 'Auteur',
        thumb: 'Lien image',
        userLikes: "Mentions j'aime",
        meta: {
          views: 'Vues'
        }
      }
    },
    job_sought: {
      name: 'Opportunité |||| Opportunités',
      fields: {
        id: 'Identifiant',
        name: 'Nom',
        authorId: 'Auteur',
        userId: 'Utilisateur',
        thumb: 'Lien image',
        createdOn: 'Opportunité créé le',
        contact: {
          availabilityDay: 'Disponiblité',
          availabilityDetail: 'Détail',
          availabilityHour: 'Créneau',
          phoneNumber: 'Téléphone',
          unwantedLocation: 'Enseignes non souhaitées',
        },
        location: {
          region: 'Région',
          department: 'Département',
          locality: 'Localité',
        }
      }
    },
    jobChoices: {
      name: 'Poste |||| Postes',
      fields: {
        category: 'Catégorie',
        cddContract: 'CDD',
        cdiContract: 'CDI',
      }
    },
    categories: {
      name: 'Catégorie publication |||| Catégories publications',
      fields: {
        id: 'Identifiant',
        name: 'Nom',
        description: 'Description',
        levelDepth: 'Niveau profondeur',
        position: 'Position',
        status: 'Statut',
        categoryId: 'Catégorie Parent',
        createdOn: 'Date de création',
        thumb: 'Lien image'
      }
    },
    companies: {
      name: 'Enseigne |||| Enseignes',
      fields: {
        id: 'Identifiant',
        name: "Nom",
        recruitmentEmail: "Email recrutement",
        description: "Description",
        active: "Actif",
        thumb: 'Lien image'
      }
    },
    job_offers: {
      name: 'Offre |||| Offres',
      fields: {
        id: 'Identifiant',
        title: "Titre",
        description: "Description",
        details: "Contenu",
        region: "Region",
        location: "Localisation",
        contractType: "Contrat",
        companyId: "Enseigne",
        active: "Actif",
      }
    },
    users: {
      name: 'Utilisateur |||| Utilisateurs',
      fields: {
        id: 'Identifiant',
        birthdate: 'Date de naissance',
        email: 'E-mail',
        enabled: 'Actif',
        gender: 'Sexe',
        onBoarded: 'Intégré',
        phoneNumber: 'Téléphone',
        firstName: 'Prénom',
        lastName: 'Nom',
        roles: 'Rôles',
        isAdmin: 'Administrateur',
        occupation: 'Poste',
        createdOn: 'Date de création',
        thumb: 'Lien image'
      }
    }
  }
}
