import React from 'react'
import { List, Datagrid, TextField, DateField, Filter, TextInput, ReferenceField, ReferenceInput, SelectInput } from 'react-admin'

const PostFilter = props => (
  <Filter {...props}>
    <TextInput label='Rechercher par contenu' source='content' alwaysOn/>
    <ReferenceInput label="Filtrer par catégorie" source="categoryId" reference="categories" perPage={10000} allowEmpty>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Filtrer par auteur" source="authorId" reference="users" perPage={10000} allowEmpty>
      <SelectInput optionText="firstName" />
    </ReferenceInput>
  </Filter>
)

export const PostList = props => (
  <List filters={<PostFilter/>} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="authorId" reference="users"><TextField source="firstName"/></ReferenceField>
      <ReferenceField source="categoryId" reference="categories"><TextField source="name" /></ReferenceField>
      <TextField source="title" />
      <TextField source="description" />
      <DateField source="createdOn" />
    </Datagrid>
  </List>
)
