import React from 'react'
import { List, Datagrid, TextField, DateField,ArrayField,SingleFieldList,ChipField, Filter, TextInput, ReferenceField, ReferenceInput, SelectInput } from 'react-admin'
import { getOpportunityTitle } from '../../helpers/opportunityHelper'

const ManufacturerFilter = props => (
  <Filter {...props}>
    <TextInput label='Filtrer par région' source='region' alwaysOn/>
    <TextInput label='Filtrer par département' source='department'/>
    <ReferenceInput label="Filtrer par utilisateur" source="userId" reference="users" perPage={10000} allowEmpty>
      <SelectInput optionText="firstName" />
    </ReferenceInput>
  </Filter>
)

export const CustomChipField  = ({source, record={}}) => {
  const values = {...record}
  values[source] = getOpportunityTitle(record[source])
  return <ChipField source={source} record={values} />
}

export const JobSoughtList = props => (
  <List filters={<ManufacturerFilter/>} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="userId" reference="users"><TextField source="firstName" /></ReferenceField>
      <TextField source="location.region" />
      <TextField source="location.department" />
      <ArrayField label="Poste" source="jobChoices"><SingleFieldList><CustomChipField source="category" /></SingleFieldList></ArrayField>
      <DateField source="createdOn" />
    </Datagrid>
  </List>
)
