import React from 'react'
import { List, Datagrid, TextField, Filter, TextInput} from 'react-admin'

const CategoryFilter = props => (
  <Filter {...props}>
    <TextInput label='Filtrer par nom' source='name' alwaysOn/>
    <TextInput label='Filtrer par description' source='description' alwaysOn/>
  </Filter>
)

export const CategoryList = props => (
  <List filters={<CategoryFilter/>} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
    </Datagrid>
  </List>
);
