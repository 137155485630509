import React from 'react'
import { List, Datagrid, BooleanInput, TextField, Filter, TextInput, BooleanField, ReferenceField, ReferenceInput, SelectInput} from 'react-admin'
import regions from '../../regions'

const JobOfferFilter = props => (
  <Filter {...props}>
    <TextInput label='Titre' source='title' alwaysOn/>
    <TextInput label='Contenu' source='details' alwaysOn/>
    <ReferenceInput label="Enseigne" source="companyId" reference="companies" perPage={10000} filter={{active: false}} allowEmpty alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SelectInput label='Région' source="region" choices={regions} alwaysOn/>
    <BooleanInput label='Offres actives' source='active' alwaysOn/>
  </Filter>
)

export const JobOfferList = props => (
  <List filters={<JobOfferFilter/>} filterDefaultValues={{ active: false }} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
      <ReferenceField source="companyId" reference="companies"><TextField source="name" /></ReferenceField>
      <TextField source="region" />
      <TextField source="location" />
      <TextField source="contractType" />
      <BooleanField source="active" />
    </Datagrid>
  </List>
);
