import React from 'react'
import { Edit, SimpleForm, ReferenceInput, SelectInput, TextInput, DateInput } from 'react-admin'

export const CategoryEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id"/>
      <TextInput source="name"/>
      <TextInput source="description"/>
      <TextInput source="thumb" />
      <DateInput disabled source="createdOn" />
      <ReferenceInput label="Créer par" source="userId" reference="users">
        <SelectInput  disabled optionText="firstName"/>
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)
