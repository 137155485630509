import * as React from 'react'
import {
  FormWithRedirect,
  DateInput,
  ReferenceInput,
  TextInput,
  SaveButton,
  DeleteButton,
  SelectInput,
  TextField,
  DateField,
  ImageInput,
  ImageField,
  required
} from 'react-admin'
import { Card, CardContent, CardHeader, Avatar, Typography, Box, Toolbar } from '@material-ui/core'
import MarkdownInput from 'ra-input-markdown';

export const PostEditForm = (props) => {
  return (
    <FormWithRedirect {...props} render={formProps => renderForm({ ...formProps })}/>
  )
}

const cardStyle = {
  margin: '0.5em',
  verticalAlign: 'top',
}

const thumbStyle = {
  objectFit: 'cover',
  width: '100%',
}

const CommentGrid = ({ comments }) => {
  if (!Array.isArray(comments) || !comments.length) {
    return null
  }

  return (
    <div style={{ margin: '1em' }}>
      {comments.map((comment, index) =>
        <Card key={comment.id} style={cardStyle}>
          <CardHeader
            title={<TextField record={comment} source="username"/>}
            subheader={<DateField record={comment} source="createdOn"/>}
            avatar={<Avatar src={comment.thumb} />}
          />
          <CardContent>
            <TextField record={comment} source="content"/>
          </CardContent>
        </Card>
      )}
    </div>
  )
}

const renderImage = (record) => {
  if (!record?.thumb) {
    return null
  }

  return (
    <img src={record?.thumb} alt="image" style={thumbStyle}/>
  )
}

const renderForm = (props) => {
  return (
    <form>
      <Box p="1em">
        <Box display="flex">
          <Box flex={2} mr="1em">
            <Typography variant="h6" gutterBottom>Contenu</Typography>
            {renderImage(props.record)}
            <ReferenceInput label="Catégorie" source="categoryId" reference="categories" validate={required()} fullWidth>
              <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput label="Titre" source="title" validate={required()} fullWidth/>
            <TextInput label="Description" source="description" multiline fullWidth/>
            <MarkdownInput label="Contenu" source="content" validate={required()} multiline fullWidth/>
            <Box mt="1em"/>
          </Box>

          <Box flex={1} ml="1em">
            <Typography variant="h6" gutterBottom>Détail</Typography>
            <TextInput label="Identifiant" disabled={true} source="id" fullWidth/>
            <ReferenceInput label="Créer par" source="authorId" reference="users" fullWidth>
              <SelectInput  disabled={true} optionText="firstName"/>
            </ReferenceInput>
            <DateInput label="Date de création" disbaled={true} source="createdOn" fullWidth/>
            <ImageInput source="file" label="Image de la publication" accept="image/*" fullWidth>
              <ImageField source="src" title="Image de la publication"/>
            </ImageInput>
          </Box>
        </Box>
      </Box>
      {props.comments && props.comments.length > 0 && (<Box p="1em"><Typography variant="h6" gutterBottom>Commentaires</Typography>{props.comments && <CommentGrid comments={props.comments}/>}</Box>)}
      <Toolbar>
        <Box display="flex" justifyContent="space-between" width="100%">
          <SaveButton
            saving={props.saving}
            handleSubmitWithRedirect={props.handleSubmitWithRedirect}
          />
          <DeleteButton
            record={props.record}
            basePath={props.basePath}
            resource={props.resource}
            undoable={props.undoable}
          />
        </Box>
      </Toolbar>
    </form>
  )
}
