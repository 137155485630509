const regions = [
  {
    id: 'Auvergne-Rhône-Alpes',
    name: 'Auvergne-Rhône-Alpes',
    departments: [
      {
        id: 'Ain',
        name: 'Ain',
      },
      {
        id: 'Allier',
        name: 'Allier',
      },
      {
        id: 'Ardèche',
        name: 'Ardèche',
      },
      {
        id: 'Cantal',
        name: 'Cantal',
      },
      {
        id: 'Drôme',
        name: 'Drôme',
      },
      {
        id: 'Isère',
        name: 'Isère',
      },
      {
        id: 'Loire',
        name: 'Loire',
      },
      {
        id: 'Haute-Loire',
        name: 'Haute-Loire',
      },
      {
        id: 'Puy-de-Dôme',
        name: 'Puy-de-Dôme',
      },
      {
        id: 'Rhône',
        name: 'Rhône',
      },
      {
        id: 'Savoie',
        name: 'Savoie',
      },
      {
        id: 'Haute-Savoie',
        name: 'Haute-Savoie',
      },
    ],
  },
  {
    id: 'Bourgogne-Franche-Comté',
    name: 'Bourgogne-Franche-Comté',
    departments: [
      {
        id: 'Cote-dor',
        name: 'Cote-dor',
      },
      {
        id: 'Doubs',
        name: 'Doubs',
      },
      {
        id: 'Jura',
        name: 'Jura',
      },
      {
        id: 'Nièvre',
        name: 'Nièvre',
      },
      {
        id: 'Haute-Saone',
        name: 'Haute-Saone',
      },
      {
        id: 'Saone-et-Loire',
        name: 'Saone-et-Loire',
      },
      {
        id: 'Yonne',
        name: 'Yonne',
      },
      {
        id: 'Territoire de Belfort',
        name: 'Territoire de Belfort',
      },
    ],
  },
  {
    id: 'Bretagne',
    name: 'Bretagne',
    departments: [
      {
        id: 'Cotes-darmor',
        name: 'Cotes-darmor',
      },
      {
        id: 'Finistère',
        name: 'Finistère',
      },
      {
        id: 'Ille-et-Vilaine',
        name: 'Ille-et-Vilaine',
      },
      {
        id: 'Morbihan',
        name: 'Morbihan',
      },
    ],
  },
  {
    id: 'Centre-Val de Loire',
    name: 'Centre-Val de Loire',
    departments: [
      {
        id: 'Cher',
        name: 'Cher',
      },
      {
        id: 'Eure-et-Loir',
        name: 'Eure-et-Loir',
      },
      {
        id: 'Indre',
        name: 'Indre',
      },
      {
        id: 'Indre-et-Loire',
        name: 'Indre-et-Loire',
      },
      {
        id: 'Loir-et-Cher',
        name: 'Loir-et-Cher',
      },
      {
        id: 'Loiret',
        name: 'Loiret',
      },
    ],
  },
  {
    id: 'Corse',
    name: 'Corse',
    departments: [
      {
        id: 'Corse-du-Sud',
        name: 'Corse-du-Sud',
      },
      {
        id: 'Haute-Corse',
        name: 'Haute-Corse',
      },
    ],
  },
  {
    id: 'Grand Est',
    name: 'Grand Est',
    departments: [
      {
        id: 'Ardennes',
        name: 'Ardennes',
      },
      {
        id: 'Aube',
        name: 'Aube',
      },
      {
        id: 'Marne',
        name: 'Marne',
      },
      {
        id: 'Haute-Marne',
        name: 'Haute-Marne',
      },
      {
        id: 'Meurthe-et-Moselle',
        name: 'Meurthe-et-Moselle',
      },
      {
        id: 'Meuse',
        name: 'Meuse',
      },
      {
        id: 'Moselle',
        name: 'Moselle',
      },
      {
        id: 'Bas-Rhin',
        name: 'Bas-Rhin',
      },
      {
        id: 'Puy-de-Dome',
        name: 'Puy-de-Dome',
      },
      {
        id: 'Haut-Rhin',
        name: 'Haut-Rhin',
      },
      {
        id: 'Vosges',
        name: 'Vosges',
      },
    ],
  },
  {
    id: 'Hauts-de-France',
    name: 'Hauts-de-France',
    departments: [
      {
        id: 'Aisne',
        name: 'Aisne',
      },
      {
        id: 'Nord',
        name: 'Nord',
      },
      {
        id: 'Oise',
        name: 'Oise',
      },
      {
        id: 'Pas-de-Calais',
        name: 'Pas-de-Calais',
      },
      {
        id: 'Somme',
        name: 'Somme',
      },
    ],
  },
  {
    id: 'Île-de-France',
    name: 'Île-de-France',
    departments: [
      {
        id: 'Paris',
        name: 'Paris',
      },
      {
        id: 'Seine-et-Marne',
        name: 'Seine-et-Marne',
      },
      {
        id: 'Yvelines',
        name: 'Yvelines',
      },
      {
        id: 'Essonne',
        name: 'Essonne',
      },
      {
        id: 'Hauts-de-Seine',
        name: 'Hauts-de-Seine',
      },
      {
        id: 'Seine-Saint-Denis',
        name: 'Seine-Saint-Denis',
      },
      {
        id: 'Val-de-Marne',
        name: 'Val-de-Marne',
      },
      {
        id: "Val-d'Oise",
        name: "Val-d'Oise",
      },
    ],
  },
  {
    id: 'Normandie',
    name: 'Normandie',
    departments: [
      {
        id: 'Calvados',
        name: 'Calvados',
      },
      {
        id: 'Eure',
        name: 'Eure',
      },
      {
        id: 'Manche',
        name: 'Manche',
      },
      {
        id: 'Orne',
        name: 'Orne',
      },
      {
        id: 'Seine-Maritime',
        name: 'Seine-Maritime',
      },
    ],
  },
  {
    id: 'Nouvelle-Aquitaine',
    name: 'Nouvelle-Aquitaine',
    departments: [
      {
        id: 'Charente',
        name: 'Charente',
      },
      {
        id: 'Charente-Maritime',
        name: 'Charente-Maritime',
      },
      {
        id: 'Corrèze',
        name: 'Corrèze',
      },
      {
        id: 'Creuse',
        name: 'Creuse',
      },
      {
        id: 'Dordogne',
        name: 'Dordogne',
      },
      {
        id: 'Gironde',
        name: 'Gironde',
      },
      {
        id: 'Landes',
        name: 'Landes',
      },
      {
        id: 'Lot-et-Garonne',
        name: 'Lot-et-Garonne',
      },
      {
        id: 'Pyrénées-Atlantiques',
        name: 'Pyrénées-Atlantiques',
      },
      {
        id: 'Deux-Sèvres',
        name: 'Deux-Sèvres',
      },
      {
        id: 'Vienne',
        name: 'Vienne',
      },
      {
        id: 'Haute-Vienne',
        name: 'Haute-Vienne',
      },
    ],
  },
  {
    id: 'Occitanie',
    name: 'Occitanie',
    departments: [
      {
        id: 'Ariège',
        name: 'Ariège',
      },
      {
        id: 'Aude',
        name: 'Aude',
      },
      {
        id: 'Aveyron',
        name: 'Aveyron',
      },
      {
        id: 'Gard',
        name: 'Gard',
      },
      {
        id: 'Haute-Garonne',
        name: 'Haute-Garonne',
      },
      {
        id: 'Gers',
        name: 'Gers',
      },
      {
        id: 'Hérault',
        name: 'Hérault',
      },
      {
        id: 'Lot',
        name: 'Lot',
      },
      {
        id: 'Lozère',
        name: 'Lozère',
      },
      {
        id: 'Hautes-Pyrénées',
        name: 'Hautes-Pyrénées',
      },
      {
        id: 'Pyrenees-Orientales',
        name: 'Pyrenees-Orientales',
      },
      {
        id: 'Tarn',
        name: 'Tarn',
      },
      {
        id: 'Tarn-et-Garonne',
        name: 'Tarn-et-Garonne',
      },
    ],
  },
  {
    id: 'Pays de la Loire',
    name: 'Pays de la Loire',
    departments: [
      {
        id: 'Loire-Atlantique',
        name: 'Loire-Atlantique',
      },
      {
        id: 'Maine-et-Loire',
        name: 'Maine-et-Loire',
      },
      {
        id: 'Mayenne',
        name: 'Mayenne',
      },
      {
        id: 'Sarthe',
        name: 'Sarthe',
      },
      {
        id: 'Vendee',
        name: 'Vendee',
      },
    ],
  },
  {
    id: "Provence-Alpes-Côte d'Azur",
    name: "Provence-Alpes-Côte d'Azur",
    departments: [
      {
        id: 'Alpes-de-Haute-Provence',
        name: 'Alpes-de-Haute-Provence',
      },
      {
        id: 'Hautes-Alpes',
        name: 'Hautes-Alpes',
      },
      {
        id: 'Alpes-Maritimes',
        name: 'Alpes-Maritimes',
      },
      {
        id: 'Bouches-du-Rhone',
        name: 'Bouches-du-Rhone',
      },
      {
        id: 'Var',
        name: 'Var',
      },
      {
        id: 'Vaucluse',
        name: 'Vaucluse',
      },
    ],
  },
  {
    id: 'Dom-Tom',
    name: 'Dom-Tom',
    departments: [
      {
        id: 'Martinique',
        name: 'Martinique',
      },
      {
        id: 'Guadeloupe',
        name: 'Guadeloupe',
      },
      {
        id: 'Guyane',
        name: 'Guyane',
      },
      {
        id: 'la Réunion',
        name: 'la Réunion',
      },
      {
        id: 'Nouvelle-Calédonie',
        name: 'Nouvelle-Calédonie',
      },
      {
        id: 'Saint-Martin',
        name: 'Saint-Martin',
      },
      {
        id: 'Saint-Barthélemy',
        name: 'Saint-Barthélemy',
      },
    ],
  },
];

export default regions;
