import React from 'react'
import { Edit, SimpleForm, DateInput, BooleanInput, TextInput, SelectArrayInput, SelectInput } from 'react-admin'
import { userRoles } from '../../constants'

export const UserEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput disabled source="email" />
      <TextInput disabled source="username" />
      <DateInput source="birthdate" />
      <BooleanInput source="enabled" />
      <SelectInput source="gender" choices={[
        { id: 'M', name: 'Masculin' },
        { id: 'F', name: 'Féminin' },
      ]} />
      <BooleanInput source="onBoarded" />
      <TextInput source="phoneNumber" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="occupation" />
      <TextInput source="thumb" />
      <SelectArrayInput label="Rôles" source="roles" choices={userRoles} />
      <DateInput disabled source="createdOn" />
    </SimpleForm>
  </Edit>
);
