import * as React from 'react'
import {
  FormWithRedirect,
  DateInput,
  ReferenceInput,
  TextInput,
  SaveButton,
  SelectInput,
  TextField,
  DateField,
  required,
  BooleanInput,
  Link
} from 'react-admin'
import { Card, CardHeader, CardContent, Avatar, Typography, Box, Toolbar } from '@material-ui/core'
import MarkdownInput from 'ra-input-markdown';
import regions from '../../regions'

export const JobEditForm = (props) => {
  return (
    <FormWithRedirect {...props} render={formProps => renderForm({ ...formProps, isEdit: props.isEdit })}/>
  )
}

const cardStyle = {
  margin: '0.5em',
  verticalAlign: 'top',
  width: 200
}

const LeadGrid = ({ leads }) => {
  if (!Array.isArray(leads) || !leads.length) {
    return null
  }

  return (
    <div style={{ margin: '1em', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'flex-end' }}>
      {leads.map((lead, index) =>
        <Link to={{ pathname: `/users/${lead.userId}`}}>
          <Card key={lead.id}    style={cardStyle}>
            <CardHeader
              title={<TextField record={lead} source="username"/>}
              subheader={<DateField record={lead} source="createdOn"/>}
              avatar={<Avatar src={lead.userThumb} />}
            />
          </Card>
        </Link>

      )}
    </div>
  )
}

const renderForm = (props) => {
  return (
    <form>
      <Box p="1em">
        <Box display="flex">
          <Box flex={2} mr="1em">
            <Typography variant="h6" gutterBottom>Contenu</Typography>
            <ReferenceInput label="Enseigne" source="companyId" reference="companies" perPage={10000} filter={{active: false}} validate={required()} fullWidth>
              <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectInput label="Type de contrat" source="contractType" choices={[
              { id: 'CDI', name: 'Contrat CDI' },
              { id: 'CDD', name: 'Contrat CDD' },
            ]} validate={required()} fullWidth/>
            <TextInput label="Titre" source="title" validate={required()} fullWidth/>
            <MarkdownInput label="Contenu" source="details" validate={required()} multiline fullWidth/>
            <Box mt="1em"/>
          </Box>

          <Box flex={1} ml="1em">
            <Typography variant="h6" gutterBottom>Localisation</Typography>
            <SelectInput label="Region" source="region" choices={regions} validate={required()} fullWidth/>
            <TextInput label="Localisation" source="location" validate={required()} fullWidth/>
            {props.isEdit && (
              <>
                <Box mt="1em"/>
                <Typography variant="h6" gutterBottom>Détail</Typography>
                <BooleanInput label="Offre active" source="active" fullWidth/>
                <TextInput label="Identifiant" disabled={true} source="id" fullWidth/>
                <DateInput label="Date de création" disbaled={true} source="createdOn" fullWidth/>
                <ReferenceInput label="Créer par" source="userId" reference="users" fullWidth>
                  <SelectInput  disabled={true} optionText="firstName"/>
                </ReferenceInput>
              </>
            )}
          </Box>
        </Box>
      </Box>
      {props.leads && props.leads.length > 0 && (<Box p="1em"><Typography variant="h6" gutterBottom>Prospects</Typography>{props.leads && <LeadGrid leads={props.leads}/>}</Box>)}
      <Toolbar>
        <Box display="flex" justifyContent="space-between" width="100%">
          <SaveButton
            saving={props.saving}
            handleSubmitWithRedirect={props.handleSubmitWithRedirect}
          />
        </Box>
      </Toolbar>
    </form>
  )
}
