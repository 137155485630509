import React from 'react'
import {
  Edit, ReferenceInput, SelectInput, TextInput, DateInput, FormWithRedirect,
  SaveButton,
  ImageInput,
  ImageField,
  BooleanInput,
  required
} from 'react-admin'
import { Typography, Box, Toolbar } from '@material-ui/core'
import MarkdownInput from 'ra-input-markdown';

const thumbStyle = {
  objectFit: 'cover',
  width: '100%',
}

const renderImage = (record) => {
  if (!record?.thumb) {
    return null
  }
  return (
    <img src={record?.thumb} alt="image" style={thumbStyle}/>
  )
}

export const CompanyEdit = props => (
  <Edit {...props}>
    <FormWithRedirect {...props} render={formProps => renderForm({ ...formProps })}/>
  </Edit>
)

const renderForm = (props) => {
  return (
    <form>
      <Box p="1em">
        <Box display="flex">
          <Box flex={2} mr="1em">
            <Typography variant="h6" gutterBottom>Contenu</Typography>
            {renderImage(props.record)}
            <TextInput label="Nom" source="name" validate={required()} fullWidth/>
            <TextInput label="Email recrutement" source="recruitmentEmail" validate={required()} fullWidth/>
            <MarkdownInput label="Description" source="description" fullWidth multiline/>
            <Box mt="1em"/>
          </Box>

          <Box flex={1} ml="1em">
            <Typography variant="h6" gutterBottom>Détail</Typography>
            <BooleanInput label="Enseigne active" source="active" />
            <TextInput label="Identifiant" disabled={true} source="id" fullWidth/>
            <ReferenceInput label="Créer par" source="userId" reference="users" fullWidth>
              <SelectInput disabled={true} optionText="firstName"/>
            </ReferenceInput>
            <DateInput label="Date de création" disbaled={true} source="createdOn" fullWidth/>
            <ImageInput source="file" label="Logo de l'enseigne" accept="image/*" fullWidth>
              <ImageField source="src" title="Logo de l'enseigne"/>
            </ImageInput>
          </Box>
        </Box>
      </Box>
      <Toolbar>
        <Box display="flex" justifyContent="space-between" width="100%">
          <SaveButton
            saving={props.saving}
            handleSubmitWithRedirect={props.handleSubmitWithRedirect}
          />
        </Box>
      </Toolbar>
    </form>
  )
}
