import React from 'react'
import { useDataProvider, Loading, Edit} from 'react-admin'
import { JobEditForm } from './JobEditForm'

export const JobOfferEdit = props => {
  const jobOfferId = props.id
  const dataProvider = useDataProvider();
  const [leads, setLeads] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();
  const params = {
    filter: {
      jobOfferId: jobOfferId,
    },
    pagination:{
      page: 1,
      perPage: 1000000
    },
    sort: {
      field: 'id',
      order: 'ASC'
    }
  }

  React.useEffect(() => {
    dataProvider.getList(`job_offer_leads`, params)
      .then((response) => {
        console.log(response.data)
        setLeads(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      })
  }, [])

  if (loading){
    return <Loading />;
  }

  return (
    <Edit {...props}>
      <JobEditForm isEdit={true} leads={leads}/>
    </Edit>
  )
};
