import React from 'react'
import { fetchUtils } from 'ra-core'
import { CompanyCreate } from './pages/companies/CompanyCreate'
import { CompanyEdit } from './pages/companies/CompanyEdit'
import { CompanyList } from './pages/companies/CompanyList'
import { JobOfferCreate } from './pages/joboffer/JobOfferCreate'
import { JobOfferEdit } from './pages/joboffer/JobOfferEdit'
import { JobOfferList } from './pages/joboffer/JobOfferList'
import * as domainMessages from './providers/i18n'
import authProvider from './providers/authProvider'
import config from './config'
import dataProvider from './providers/dataProvider'
import frenchMessages from 'ra-language-french'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import { Admin, Resource, EditGuesser, ListGuesser, ShowGuesser } from 'react-admin'
import { CategoryCreate } from './pages/categories/CategoryCreate'
import { CategoryEdit } from './pages/categories/CategoryEdit'
import { CategoryList } from './pages/categories/CategoryList'
import { JobSoughtShow } from './pages/jobsought/jobSoughtShow'
import { JobSoughtList } from './pages/jobsought/jobSoughtList'
import { PostCreate } from './pages/posts/PostCreate'
import { PostEdit } from './pages/posts/PostEdit'
import { PostList } from './pages/posts/PostList'
import { UserCreate } from './pages/users/UserCreate'
import { UserEdit } from './pages/users/UserEdit'
import { UserList } from './pages/users/UserList'

const translations = {
  ...frenchMessages,
  ...domainMessages.resourcesFrMessages
}

const i18nProvider = polyglotI18nProvider(() => translations, 'fr')

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    let headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')
    options.headers = headers
  }
  const token = localStorage.getItem('token')
  options.headers.set('Authorization', `Bearer ${token}`)
  return fetchUtils.fetchJson(url, options)
}

const isAdmin = (roles) => {
  if (!roles || !Array.isArray(roles)) {
    return false
  }
  return roles.indexOf('ROLE_ADMIN') !== -1
}

const App = () => (
  <Admin i18nProvider={i18nProvider} authProvider={authProvider}
         dataProvider={dataProvider(config.API_URL, httpClient)}>
    {
      permissions => [
        isAdmin(permissions) ? <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate}/> : null,
        isAdmin(permissions) ? <Resource name="categories" list={CategoryList} edit={CategoryEdit}
                                         create={CategoryCreate}/> : null,
        isAdmin(permissions) ? <Resource name="posts" list={PostList} edit={PostEdit} create={PostCreate}/> : null,
        isAdmin(permissions) ? <Resource name="job_sought" list={JobSoughtList} edit={JobSoughtShow}/> : null,
        isAdmin(permissions) ? <Resource name="companies" list={CompanyList} create={CompanyCreate}
                                         edit={CompanyEdit}/> : null,
        isAdmin(permissions) ? <Resource name="job_offers" list={JobOfferList} create={JobOfferCreate}
                                         edit={JobOfferEdit}/> : null,
        isAdmin(permissions) ? <Resource name="comments"/> : null,
      ]
    }
  </Admin>
)

export default App
